@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-Medium.eot');
    src: url('SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('SFProDisplay-Medium.woff2') format('woff2'),
        url('SFProDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-Bold.eot');
    src: url('SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('SFProDisplay-Bold.woff2') format('woff2'),
        url('SFProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-Regular.eot');
    src: url('SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('SFProDisplay-Regular.woff2') format('woff2'),
        url('SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-Black.eot');
    src: url('SFProDisplay-Black.eot?#iefix') format('embedded-opentype'),
        url('SFProDisplay-Black.woff2') format('woff2'),
        url('SFProDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

