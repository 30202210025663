@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?5tj8x3');
  src:  url('fonts/icomoon.eot?5tj8x3#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?5tj8x3') format('truetype'),
    url('fonts/icomoon.woff?5tj8x3') format('woff'),
    url('fonts/icomoon.svg?5tj8x3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="kl-"], [class*=" kl-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kl-business-management:before {
  content: "\e900";
  color: #fff;
}
.kl-Icon-awesome-bell:before {
  content: "\e901";
}
.kl-Icon-awesome-edit:before {
  content: "\e902";
}
.kl-Icon-awesome-eye:before {
  content: "\e903";
}
.kl-Icon-feather-arrow-left:before {
  content: "\e904";
}
.kl-Icon-ionic-ios-add-circle:before {
  content: "\e905";
}
.kl-Icon-material-delete:before {
  content: "\e906";
  color: #fff;
}
.kl-icon-admin-earning:before {
  content: "\e907";
  color: #fff;
}
.kl-icon-booking:before {
  content: "\e908";
  color: #fff;
}
.kl-icon-booking-management:before {
  content: "\e909";
  color: #fff;
}
.kl-icon-business-earning:before {
  content: "\e90a";
  color: #fff;
}
.kl-icon-business-management:before {
  content: "\e90b";
  color: #fff;
}
.kl-icon-dashboard:before {
  content: "\e90c";
  color: #fff;
}
.kl-icon-event:before {
  content: "\e90d";
  color: #fff;
}
.kl-icon-event-management:before {
  content: "\e90e";
  color: #fff;
}
.kl-icon-hand:before {
  content: "\e90f";
  color: #fff;
}
.kl-icon-logout:before {
  content: "\e910";
  color: #fff;
}
.kl-icon-my-earning:before {
  content: "\e911";
  color: #fff;
}
.kl-icon-service:before {
  content: "\e912";
  color: #fff;
}
.kl-icon-service-management:before {
  content: "\e913";
  color: #fff;
}
.kl-icon-settings:before {
  content: "\e914";
  color: #fff;
}
.kl-icon-ticket-management:before {
  content: "\e915";
  color: #fff;
}
.kl-icon-user:before {
  content: "\e916";
  color: #fff;
}
.kl-icon-user-management:before {
  content: "\e917";
  color: #fff;
}
